import React, { Suspense, useEffect, useRef } from "react";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";

import PublicRouter from "../frontend/PublicRouter";
import { BrowserRouter as Router } from "react-router-dom";

import { GlobalStyle } from "../frontend/components/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../frontend/components/themes";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import ScrollToTop from "./ScrollToTop";

import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import Loader from "../Loader";
import { MainContainer } from "../frontend/components/styles/homeStyle";
import { Toaster } from "react-hot-toast";
import { MetaContextProvider } from "../stores/MetaContext";

const App = () => {
      const scrollRef = useRef(null);

      useEffect(() => {
            AOS.init();
            console.log("notdone");
            setTimeout(() => {
                  $(".ball").fadeOut("slow");
            }, 2000);
            $("#preloader").delay(4000).fadeOut("slow");
      }, []);

      useEffect(() => {
            const script = document.createElement("script");
            script.src = "/js/script.js";
            script.async = true;
            script.async = true;
            document.body.appendChild(script);
            return () => {
                  document.body.removeChild(script);
            };
      }, []);

      // useEffect(() => {
      //       const scrollElement = scrollRef.current;

      //       gsap.to(scrollElement, {
      //             y: () => -window.scrollY,
      //             ease: 'power1.out',
      //             scrollTrigger: {
      //                   trigger: scrollElement,
      //                   start: 'top top',
      //                   end: 'bottom top',
      //                   scrub: true,
      //             },
      //       });
      // }, []);

      return (
            <>
                  <MetaContextProvider>
                        <Loader />
                        <MainContainer className="" ref={scrollRef}>
                              <GlobalStyle />
                              <Toaster position="top-right" />
                              <ThemeProvider theme={lightTheme}>
                                    <Router>
                                          <ScrollToTop />
                                          <Header />
                                          <Suspense fallback="Loading">
                                                <PublicRouter />
                                          </Suspense>
                                          <Footer />
                                    </Router>
                              </ThemeProvider>
                        </MainContainer>
                  </MetaContextProvider>
            </>
      );
};

export default App;
