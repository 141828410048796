import React from 'react';

import {
      FooterItemContent,
      FooterItemTitle,
      FooterLink,
      FooterLinkContainer,
      FooterLinkItem,
      FooterNavigation
} from '../../frontend/components/layouts/footer';
import { Col, Row } from 'react-bootstrap';
import FooterCategoryItem from '../../frontend/components/footerCategory/FooterCategoryItem';
import { SubscribeFooter } from './SubscribeFooter';

export const LinkFooter = () => {
      return (
            <>
                  <FooterLink>
                        <Row className="g-0">
                              <Col lg={6}>
                                    <FooterNavigation>
                                          <img
                                                src="/images/img/footer/footer.png"
                                                alt="prime footer map"
                                                className="img-fluid"
                                          />
                                          {/* <VideoComponent/> */}
                                    </FooterNavigation>
                              </Col>
                              <Col lg={6}>
                                    <FooterLinkContainer>
                                          <Row>
                                                <FooterLinkItem>
                                                      <FooterItemTitle>
                                                            <h2>About Us</h2>
                                                      </FooterItemTitle>
                                                      <FooterItemContent
                                                            lineHeight={1.8}
                                                            fontSize="1rem"
                                                            fontWeight="400"
                                                            textColor="#a1a1a1"
                                                      >
                                                            <ul>
                                                                  <FooterCategoryItem
                                                                        title="Who we are?"
                                                                        slug="about-us"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="Career"
                                                                        slug="careers"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="CSR"
                                                                        slug="csr"
                                                                  />
                                                            </ul>
                                                      </FooterItemContent>
                                                </FooterLinkItem>

                                                <FooterLinkItem>
                                                      <FooterItemTitle>
                                                            <h2>Useful Link</h2>
                                                      </FooterItemTitle>
                                                      <FooterItemContent
                                                            lineHeight={1.8}
                                                            fontSize="1rem"
                                                            fontWeight="400"
                                                            textColor="#a1a1a1"
                                                      >
                                                            <ul>
                                                                  <FooterCategoryItem
                                                                        title="FAQs"
                                                                        slug="faqs"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="Contact Us"
                                                                        slug="contact-us"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="Dealer Contact"
                                                                        slug="dealer-contact"
                                                                  />
                                                            </ul>
                                                      </FooterItemContent>
                                                </FooterLinkItem>

                                                <FooterLinkItem>
                                                      <FooterItemTitle>
                                                            <h2>Quick Link</h2>
                                                      </FooterItemTitle>
                                                      <FooterItemContent
                                                            lineHeight={1.8}
                                                            fontSize="1rem"
                                                            fontWeight="400"
                                                            textColor="#a1a1a1"
                                                      >
                                                            <ul>
                                                                  <FooterCategoryItem
                                                                        title="Blog / Event"
                                                                        slug="blogs"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="Privacy Policy"
                                                                        slug="privacy-policy"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="Terms & Condition"
                                                                        slug="terms-and-condition"
                                                                  />
                                                            </ul>
                                                      </FooterItemContent>
                                                </FooterLinkItem>

                                                <FooterLinkItem>
                                                      <FooterItemTitle>
                                                            <h2>Contact Us</h2>
                                                      </FooterItemTitle>
                                                      <FooterItemContent
                                                            lineHeight={1.8}
                                                            fontSize="1rem"
                                                            fontWeight="400"
                                                            textColor="#a1a1a1"
                                                      >
                                                            <ul>
                                                                  <li>
                                                                        <a href="tel:18105000062">
                                                                              Toll-Free
                                                                              No:
                                                                              18105000062
                                                                        </a>
                                                                  </li>
                                                                  <FooterCategoryItem
                                                                        title="Tripureshwor, Kathmandu"
                                                                        slug="#"
                                                                  />
                                                                  <FooterCategoryItem
                                                                        title="info@primeceramics.com.np"
                                                                        slug="#"
                                                                  />
                                                            </ul>
                                                      </FooterItemContent>
                                                </FooterLinkItem>
                                          </Row>

                                          <SubscribeFooter />
                                    </FooterLinkContainer>
                              </Col>
                        </Row>
                  </FooterLink>
            </>
      );
};
