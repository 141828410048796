import React, { useState } from 'react';
import './contactStyle.css';
import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
      Form,
      Row,
      Modal,
      Col
} from 'react-bootstrap';
import Breadcrumb from '../components/common/Breadcrumb';
import { DealerForm } from '../components/styles/dealerStyle';
import {
      ContactFormButton,
      ContactFormContainer
} from '../components/styles/contactStyle';
import { Helmet } from 'react-helmet';

const ContactContainer = () => {
      const [contacts, setContacts] = useState([]);
      const [loading, setLoading] = useState(false);
      const [show, setShow] = useState(false);

      const [frameMap, setFrameMap] = useState('');

      const handleClose = () => setShow(false);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);

                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/dealer_contacts`
                        )
                        .then((response) => {
                              setContacts(response.data.contacts);
                              setLoading(false);
                        })
                        .catch((error) => {
                              toast.error(error);
                              setLoading(false);
                        });
            };

            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Dealers | Prime Tiles with Stile</title>
                  </Helmet>
                  <Breadcrumb title="Dealers" summary="Dealers" />
                  <div className="container mb-5 pb-5">
                        <div className="row mt-5 align-items-start">
                              <div className="col-md-4">
                                    <div className="dealer__contact-item">
                                          <div className="mb-3">
                                                <div className="about__summary pb-0 fs-4">
                                                      Toll Free No:
                                                </div>

                                                <div className="ps-3">
                                                      <a href="tel:18105000062">
                                                            18105000062
                                                      </a>
                                                </div>
                                          </div>
                                          <div>
                                                <div className="about__summary pb-0 fs-4">
                                                      Head Office:
                                                </div>
                                                <span className="ps-3">
                                                      +977 - 1 - 5918860 | 61 |
                                                      62
                                                </span>
                                          </div>
                                    </div>
                                    <DealerForm>
                                          <h3 className="fs-4 pt-4">
                                                Contact Our Dealer
                                          </h3>
                                          <ContactFormContainer className="w-100 shadow-none">
                                                <Form
                                                      role="form"
                                                      className="php-email-form"
                                                >
                                                      <Row className="g-3">
                                                            <Form.Group className="col-lg-12">
                                                                  <Form.Label>
                                                                        Your
                                                                        Name
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                        type="text"
                                                                        required
                                                                  />
                                                            </Form.Group>

                                                            <Form.Group className="col-lg-12">
                                                                  <Form.Label>
                                                                        Your
                                                                        Email
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                        type="email"
                                                                        required
                                                                  />
                                                            </Form.Group>

                                                            <Form.Group className="col-lg-12">
                                                                  <Form.Label>
                                                                        Phone
                                                                        No.
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                        type="text"
                                                                        required
                                                                  />
                                                            </Form.Group>

                                                            <Form.Group className="col-lg-12">
                                                                  <Form.Label>
                                                                        Message
                                                                  </Form.Label>
                                                                  <Form.Control
                                                                        as="textarea"
                                                                        rows="8"
                                                                        required
                                                                  >
                                                                        {' '}
                                                                  </Form.Control>
                                                            </Form.Group>
                                                      </Row>
                                                      <ContactFormButton>
                                                            <button type="submit">
                                                                  Send Message
                                                                  {loading ? (
                                                                        <span
                                                                              className="spinner-border spinner-border-sm ml-5"
                                                                              role="status"
                                                                              aria-hidden="true"
                                                                        ></span>
                                                                  ) : (
                                                                        <span></span>
                                                                  )}
                                                            </button>
                                                      </ContactFormButton>
                                                </Form>
                                          </ContactFormContainer>
                                    </DealerForm>
                              </div>

                              <Col lg={8}>
                                    <img
                                          src="/images/prime-map1.png"
                                          className="rounded"
                                          alt="Prime Dealer Map"
                                          width="100%"
                                          height="100%"
                                          style={{ objectFit: 'contain' }}
                                    />
                              </Col>

                              {/* <FilterDealer
                                    selectedDistrict={selectedDistrict}
                                    setSelectedDistrict={setSelectedDistrict}
                                    selectedProvince={selectedProvince}
                                    setSelectedProvince={setSelectedProvince}
                                    selectedZone={selectedZone}
                                    setSelectedZone={setSelectedZone}
                              >
                                    {!loading ? (
                                          <>
                                                {contacts.length > 0 ? (
                                                      <>
                                                            <div className="dealer__contact-container ">
                                                                  <div className="row g-4">
                                                                        {contacts
                                                                              .filter(
                                                                                    (
                                                                                          dealer
                                                                                    ) =>
                                                                                          selectedDistrict
                                                                                                ? dealer.district ===
                                                                                                  selectedDistrict
                                                                                                : true
                                                                              )
                                                                              .filter(
                                                                                    (
                                                                                          dealer
                                                                                    ) =>
                                                                                          selectedZone
                                                                                                ? dealer.zone ===
                                                                                                  selectedZone
                                                                                                : true
                                                                              )
                                                                              .filter(
                                                                                    (
                                                                                          dealer
                                                                                    ) =>
                                                                                          selectedProvince
                                                                                                ? dealer.province ===
                                                                                                  selectedProvince
                                                                                                : true
                                                                              )
                                                                              .map(
                                                                                    (
                                                                                          contact,
                                                                                          index
                                                                                    ) => (
                                                                                          <div
                                                                                                className="col-md-6 col-sm-6"
                                                                                                key={
                                                                                                      index
                                                                                                }
                                                                                          >
                                                                                                <div className="dealer__contact-item">
                                                                                                      <h4 className="dealer__contact-title">
                                                                                                            {
                                                                                                                  contact.title
                                                                                                            }
                                                                                                      </h4>
                                                                                                      <p>
                                                                                                            {
                                                                                                                  contact.full_name
                                                                                                            }
                                                                                                      </p>
                                                                                                      {contact.email && (
                                                                                                            <div className="dealer__contact-icon">
                                                                                                                  <i className="bx bx-envelope"></i>
                                                                                                                  <div>
                                                                                                                        <a href="mailto:{contact.email}"></a>

                                                                                                                        {
                                                                                                                              contact.email
                                                                                                                        }
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      )}
                                                                                                      {contact.phone && (
                                                                                                            <div className="dealer__contact-icon">
                                                                                                                  <i className="bx bx-phone"></i>
                                                                                                                  <a href="tel:{contact.phone}">
                                                                                                                        +977{' '}
                                                                                                                        {
                                                                                                                              contact.phone
                                                                                                                        }
                                                                                                                  </a>
                                                                                                            </div>
                                                                                                      )}
                                                                                                      {contact.address && (
                                                                                                            <div className="dealer__contact-icon">
                                                                                                                  <i className="bx bx-map"></i>

                                                                                                                  <div
                                                                                                                        className="dealer__location"
                                                                                                                        onClick={(
                                                                                                                              event
                                                                                                                        ) =>
                                                                                                                              handleMap(
                                                                                                                                    event,
                                                                                                                                    index
                                                                                                                              )
                                                                                                                        }
                                                                                                                  >
                                                                                                                        {`${contact.address}, ${contact.district} , ${contact.zone}, ${contact.province}`}
                                                                                                                  </div>
                                                                                                            </div>
                                                                                                      )}
                                                                                                </div>
                                                                                          </div>
                                                                                    )
                                                                              )}
                                                                  </div>
                                                            </div>
                                                      </>
                                                ) : (
                                                      <>
                                                            <div
                                                                  className="text-center"
                                                                  style={{
                                                                        height: '50vh',
                                                                        paddingTop:
                                                                              '20vh'
                                                                  }}
                                                            >
                                                                  <h4>
                                                                        No
                                                                        Dealer
                                                                        Contact
                                                                        Found
                                                                  </h4>
                                                            </div>
                                                      </>
                                                )}
                                          </>
                                    ) : (
                                          <>
                                                <div
                                                      className="text-center"
                                                      style={{
                                                            height: '50vh',
                                                            paddingTop: '20vh'
                                                      }}
                                                >
                                                      <Spinner
                                                            animation="border"
                                                            role="status"
                                                      >
                                                            <span className="visually-hidden">
                                                                  Loading...
                                                            </span>
                                                      </Spinner>
                                                </div>
                                          </>
                                    )}
                              </FilterDealer> */}
                        </div>
                  </div>
                  <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                              <Modal.Title>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <iframe
                                    src={frameMap}
                                    width="600"
                                    height="450"
                                    className="border-0"
                                    allowfullscreen=""
                                    loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"
                              ></iframe>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default ContactContainer;
