import React, { useState } from 'react';
import Product from '../category/Product';
import { Modal } from 'react-bootstrap';
import Visualizer from '../visualizer';

const RelatedProduct = ({ products, loading }) => {
      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      function handleShow(breakpoint) {
            setFullscreen(breakpoint);
            setShow(true);
      }

      return (
            <>
                  {!loading ? (
                        <>
                              <div className="">
                                    <h4>Related Product</h4>
                              </div>
                              <div className="category__container ">
                                    <div className="row g-4">
                                          {products?.length > 0 ? (
                                                products.map(
                                                      (product, index) => (
                                                            <div
                                                                  className="col-md-3"
                                                                  key={index}
                                                            >
                                                                  <Product
                                                                        product={
                                                                              product
                                                                        }
                                                                        handleShow={
                                                                              handleShow
                                                                        }
                                                                  />
                                                            </div>
                                                      )
                                                )
                                          ) : (
                                                <></>
                                          )}
                                    </div>
                              </div>
                        </>
                  ) : (
                        <></>
                  )}
                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default RelatedProduct;
